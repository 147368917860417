<template>
  <div class="relative">
    <h1 class="gradient-text text-center p-8">CarDex</h1>
  </div>
<!--  <div
    id="carouselFade"
    class="carousel-mx-auto carousel slide px-wide-300 p-16"
    data-ride="carousel"
  >
    <div class="carousel-inner carousel-mx-auto">
      <div class="carousel-item active">
        <img
          src="@/styles/car-logos/alfa-romeo.svg"
          class="d-block"
          data-interval="3000"
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/bmw.svg"
          class="d-block"
          data-interval="3000"
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/fiat.svg"
          class="d-block"
          data-interval="3000"
          alt="..."
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/lamborghini.svg"
          class="d-block"
          alt="..."
          data-interval="3000"
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/mitsubishi.svg"
          class="d-block0"
          alt="..."
          data-interval="3000"
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/nissan.svg"
          class="d-block"
          data-interval="3000"
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/suzuki.svg"
          class="d-block"
          data-interval="3000"
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/tesla.svg"
          class="d-block"
          alt="..."
          data-interval="3000"
        />
      </div>
      <div class="carousel-item">
        <img
          src="@/styles/car-logos/toyota.svg"
          class="d-block"
          data-interval="3000"
        />
      </div>
    </div> 
    <button class="carousel-control-prev" type="button" data-target="#carouselExampleFade" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-target="#carouselExampleFade" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </button>
  </div> -->

  

  <!-- <div class="timeline-root">
    <section class="section intro">

    </section>

    <section class="timeline">
      <div class="info container">
        <i class="car-mercedes-benz p-8 mx-auto" style="font-size: 13vw;"></i>
        <h2>Mercedes, the first car manufacturer.</h2>
        <p>Did you know that the first car ever was invented by Mercedes?</p>
      </div>

      <ol>
        <li>
          <div>
            <time>1885</time> the <button data-tooltip-target="tooltip-light" type="button" class="text-black"><a href=""
                onclick="window.open('https://upload.wikimedia.org/wikipedia/commons/7/71/1885Benz.jpg','targetWindow', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1090px, height=550px, top=25px left=120px'); return false;"><strong>Benz
                  Patent-Motorwagen</strong></a></button>
            <div id="tooltip-light" role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              Click to see inage
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div> was the first car put into production, and its original
            cost was 600 imperial German marks, equivalent to $4,900 in 2022.
          </div>
        </li>
        <li>
          <div>
            <time>1937</time> Proin quam velit, efficitur vel neque vitae, rhoncus commodo mi. Suspendisse
            finibus mauris et bibendum molestie. Aenean ex augue, varius et pulvinar in, pretium non nisi.
          </div>
        </li>
        <li></li>
      </ol>

    </section>
  </div>-->
</template>

<script>
// @ is an alias to /src
/*
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}*/
</script>
<style scoped>
@import "@/styles/HomeView.css";
@import "@/styles/TextDecorationNone.css";
</style>
